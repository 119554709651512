#scollId {
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 45vh; */
  height: calc(100vh - 340px);
  margin: 0 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8eaed;
  padding-right: 0px;
}
.properties-card-edit {
  position: absolute;
  right: 16px;
}
.properties-season-card-vertical {
  margin: 0px 5px;
}
.properties-season-card-delete {
  color: #f74141;
}
.season-card-edit {
  margin-left: 24px;
}
.confirmation-modal-you-are {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #2f3031;
}
.confirmation-modal-name {
  font-weight: 500;
}
.component-properties-season {
  margin-left: 24px;
  padding-top: 24px;
  color: #2f3031;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.component-properties-season-card {
  width: 100%;
  overflow-x: auto;
  display: flex;
  padding-left: 24px;
  margin-bottom: 0px !important;
  border-bottom: transparent !important;
}

.allFields {
  overflow-y: auto;
}
.count-crops,
.area-icon {
  color: #2f3031;
}
.no-crops,
.no-area-icon {
  color: #8e9293;
}

#scollId .ant-select-selection:hover *,
.shape-file-list .ant-select-selection:hover * {
  color: #aab400;
}
