div.leaflet-pm-actions-container 
    a.leaflet-pm-action.action-finish {
        padding-right: 60px
    }

div.leaflet-pm-actions-container 
    a.leaflet-pm-action.action-removeLastVertex {
        padding-right: 180px;
    }

div.leaflet-pm-actions-container 
    a.leaflet-pm-action.action-cancel {
        padding-right: 60px;
    }

