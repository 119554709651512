.innnerTabTable {
  width: 100%;
  margin-top: 15px;
}
.innnerTabTable .ant-table-body {
  font-size: 13px !important;
}
.innnerTabTable .ant-table-column-title {
  font-weight: 600;
}
.innnerTabTable .ant-table-thead > tr > th, 
.innnerTabTable .ant-table-tbody > tr > td {
  padding: 10px 15px;
  font-size: 13px;
}

.ant-table-column-has-actions .ant-table-column-has-sorters .ant-table-row-cell-break-word {
  width:200px;
}
.innnerTabTable .ant-table-fixed-header .ant-table-scroll .ant-table-header{
  overflow:unset;
}
