/* add css module styles here (optional) */
.actionDiv {
  width: 0px !important;
}
.actionDiv .editBtn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.actionDiv .deleteBtn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
